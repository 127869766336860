<template>
  <!-- 
    The login-component. Used to sign up to your account (outdated: app now uses keycloak).
  -->
  <Portlet
    :title="$t('loginComponent.login')" 
    :sub-title="$t('loginComponent.onAlturosOnsiteSuite')" 
    icon="lock" 
    class="login"
  >
    <div
      v-if="loggedIn"
    >
      <div
        class="alert alert-warning"
        role="alert"
      >
        {{ $t('loginComponent.noPermission') }}
      </div>
      <a
        class="btn btn-dark btn-block"
        href="#"
        @click.prevent="authenticationLogout()"
      >Logout</a>
    </div>
    <a
      v-else
      class="btn btn-dark btn-block"
      href="#"
      @click.prevent="authenticationLogin()"
    >{{ $t('loginComponent.loginViaKeycloak') }}</a>
  </Portlet>
  <!-- <LoadingPlaceholder v-else/> -->
</template>

<script>
  import {authenticationMixin} from '@/mixins/authenticationMixin.js';

  export default {
    name: 'Login',
    mixins: [
      authenticationMixin
    ]
  }
</script>

<style scoped>
  .login {
    max-width: 600px;
    margin: 5rem auto;
  }
</style>
